import React from 'react';
import { Link } from 'react-router-dom';
import SvgComponent from './logo';
import { useSelector, useDispatch } from 'react-redux';
import { setLang, setActivePage } from '../../redux/components/firstSlice';

const Header = () => {
  const dispatch = useDispatch();
  const { lang, dataM2, dataItemsM } = useSelector((state) => state.filters);
  const langList = [
    { langTitle: 'Рус', langCode: 'ru' },
    { langTitle: 'Қаз', langCode: 'kz' },
  ];
  const [isOpen, setIsOpen] = React.useState(false);

  /*   const { activePage } = useSelector((state) => state.filters); */

  const uniqueProducts = dataItemsM.filter(
    (obj, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.base.typeOfProduct === obj.base.typeOfProduct &&
          t.needLang.nameOfProduct[lang] === obj.needLang.nameOfProduct[lang],
      ),
  );

  /*  const chooseProduct = (typeOfProduct) => {
    dispatch(setActivePage(typeOfProduct));
    window.scrollTo(0, 0);
  }; */

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLink1 = (typeOfProduct) => {
    setIsOpen(!isOpen);
    dispatch(setActivePage(typeOfProduct));
    window.scrollTo(0, 0);
  };

  /*   const toggleLink2 = () => {
    setIsOpen(!isOpen);
    window.scrollTo(0, 6000);
  }; */
  return (
    <div className='header-block'>
      <header className='header'>
        <div className='header-left'>
          <div>
            {/* <Link className='logo' to={'/'} onClick={() => window.scrollTo(0, 0)}>Yepo.kz</Link> */}
            <SvgComponent />
          </div>
          <div className='main-pages'>
            {dataM2.Header.LeftTop[lang]}
            <br />
            {dataM2.Header.LeftBottom[lang]}
          </div>
        </div>

        <div className='header-right'>
          <button
            className='burger-menu-button'
            onClick={toggleMenu}>
            {dataM2.Header.Catalog[lang]}
          </button>
          {isOpen && (
            <ul className='activeUl'>
              {uniqueProducts.map((obj, i) => (
                <Link
                  key={i}
                  onClick={() => toggleLink1(obj.base.typeOfProduct)}
                  to={`/Products?name=${obj.base.typeOfProduct}`}>
                  {obj.needLang.nameOfProduct[lang]}
                </Link>
              ))}
              <Link onClick={() => window.scrollTo(0, 6000)}>{dataM2.Header.Contacts[lang]}</Link>
            </ul>
          )}
          <Link
            className='pages'
            onClick={() => window.scrollTo(0, 6000)}>
            {dataM2.Header.Contacts[lang]}
          </Link>

          <ul className='lang'>
            {langList.map((obj, i) => {
              return (
                <li
                  key={i}
                  className={lang === obj.langCode ? 'active' : ''}
                  onClick={() => dispatch(setLang(obj.langCode))}>
                  {obj.langTitle}
                </li>
              );
            })}
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
