/* ---------Стиль---------- */
import './scss/App.scss';
/* ---------Реакт---------- */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
/* ---------Компоненты */
import Header from './components/Header/index.jsx';
import Footer from './components/Footer/index.jsx';
/* ---------Страницы---------- */
import Products from './pages/mainPages/Products.jsx';
import AboutLaptop from "./pages/specialPages/AboutLaptop"
import AboutDisplay from "./pages/specialPages/AboutDisplay"
import AboutProjector from './pages/specialPages/AboutProjector';
import AboutTablet from './pages/specialPages/AboutTablet';
import NotFound from './pages/mainPages/NotFound';
import { Admin } from './pages/mainPages/Admin';
import { MainPage } from './pages/mainPages/MainPage';
import AboutWatch from './pages/specialPages/AboutWatch';
import AboutHeadphones from './pages/specialPages/AboutHeadphones.jsx';
import AboutMouse from './pages/specialPages/AboutMouse.jsx';


/* ---------Функциональный компонент---------- */
function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/Products" element={<Products />} />
        <Route path='/AboutProjector' element={<AboutProjector />} />
        <Route path='/AboutLaptop' element={<AboutLaptop />} />
        <Route path='/AboutDisplay' element={<AboutDisplay />} />
        <Route path='/AboutTablet' element={<AboutTablet />} />
        <Route path='/AboutWatch' element={<AboutWatch />} />
        <Route path='/AboutHeadphones' element={<AboutHeadphones />} />
        <Route path='/AboutMouse' element={<AboutMouse />} />
        <Route path="Admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
