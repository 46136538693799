import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ItemBlock = ({ obj, index }) => {
  const { dataM2, lang } = useSelector((state) => state.filters);

  // Определяем правильный путь для каждой страницы в зависимости от типа продукта
  const getLinkForType = (typeOfProduct, code) => {
    switch (typeOfProduct) {
      case 'Laptop':
        return `/AboutLaptop?name=${encodeURIComponent(code)}`;
      case 'Display':
        return `/AboutDisplay?name=${encodeURIComponent(code)}`;
      case 'Projector':
        return `/AboutProjector?name=${encodeURIComponent(code)}`;
      case 'Tablet':
        return `/AboutTablet?name=${encodeURIComponent(code)}`;
      case 'Watch':
        return `/AboutWatch?name=${encodeURIComponent(code)}`;
      case 'Headphones':
        return `/AboutHeadphones?name=${encodeURIComponent(code)}`;
      case 'Mouse':
        return `/AboutMouse?name=${encodeURIComponent(code)}`;
      default:
        return '/'; // Резервный вариант
    }
  };

  return (
    <section className='container'>
      <div
        className='container-block'
        key={obj.base.code}>
        {index % 2 === 0 ? (
          <>
            <div className='image-block'>
              <div className='image-block1'>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                  <img
                    src={obj.base.mainImages}
                    alt='ef'
                  />
                </Link>
              </div>
            </div>
            <div className='text-block'>
              <div>
                <h3>YEPO {obj.base.seria}</h3>
                <p className='text-block-description'>{obj.needLang.text[lang]}</p>
                <p>
                  {obj.needLang.mainD1[lang]}: {obj.needLang.mainDA1[lang]}
                  <br />
                  {obj.needLang.mainD2[lang]}: {obj.needLang.mainDA2[lang]}
                  <br />
                  {obj.needLang.mainD3[lang]}: {obj.needLang.mainDA3[lang]}
                  <br />
                  {dataM2.BaseBlock.Price[lang]} :{' '}
                  {new Intl.NumberFormat('ru-RU').format(obj.base.price)} ₸
                </p>
                <div>
                  <button>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                      {dataM2.BaseBlock.About[lang]}
                    </Link>
                  </button>
                  <button>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                      {dataM2.BaseBlock.Buy[lang]}
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='text-block'>
              <div>
                <h3>YEPO {obj.base.seria}</h3>
                <p className='text-block-description'>{obj.needLang.text[lang]}</p>
                <p>
                  {obj.needLang.mainD1[lang]}: {obj.needLang.mainDA1[lang]}
                  <br />
                  {obj.needLang.mainD2[lang]}: {obj.needLang.mainDA2[lang]}
                  <br />
                  {obj.needLang.mainD3[lang]}: {obj.needLang.mainDA3[lang]}
                  <br />
                  {dataM2.BaseBlock.Price[lang]} :{' '}
                  {new Intl.NumberFormat('ru-RU').format(obj.base.price)} ₸
                </p>
                <div>
                  <button>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                      {dataM2.BaseBlock.About[lang]}
                    </Link>
                  </button>
                  <button>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                      {dataM2.BaseBlock.Buy[lang]}
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='image-block'>
              <div className='image-block1'>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={getLinkForType(obj.base.typeOfProduct, obj.base.code)}>
                  <img
                    src={obj.base.mainImages}
                    alt='ef'
                  />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ItemBlock;
